import axios from 'axios';
import {
    bool
} from 'jshint/src/options';

window.axios = axios;

// Add a request interceptor
window.axios.interceptors.request.use(function (config) {
    /*
    if (String(sessionStorage.getItem('demoMode')) == 'true') {
        return Promise.reject({});
    }
    */
    // Do something before request is sent
    const token = localStorage.getItem('auth_token');
    const custom_index = sessionStorage.getItem('custom_index');
    config.params = {
        or: 'wb'
    }
    config.headers = {
        Authorization: 'Bearer ' + token,
        "Accept": "application/json, text/plain, * /*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": true,
        "Customer-Index": custom_index ? custom_index : 'False'
    };
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


// Add a response interceptor
window.axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.auth.signout();
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});