//
// list.js
// Theme module
//
import List from 'list.js';

export default (function() {
    const lists = document.querySelectorAll('[data-list]');
    const sorts = document.querySelectorAll('[data-sort]');

    if (typeof List !== 'undefined' && sorts) {
        [].forEach.call(sorts, function(sort) {
            sort.addEventListener('click', function(e) {
                e.preventDefault();
            });
        });
    }
})();

// Make available globally
window.List = List;