import Firebase from "firebase";

Firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
        return true;
    } else {
        const path = window.location.pathname.replace('/', '').replace('.html', '').replace('dist/', '');
        const nonRedirectPath = ['index', 'password-reset-cover'];
        if (nonRedirectPath.indexOf(path) < 0)
            window.location.href = "index.html";
    }
});

const signin = async function() {
    try {
        await window.firebase
            .auth()
            .signInWithEmailAndPassword(document.getElementById('address').value, document.getElementById('password').value)
            .then((result) => {
                window.firebase.auth().currentUser.getIdTokenResult()
                    .then(function(idTokenResult) {
                        localStorage.setItem('auth_claims', JSON.stringify(idTokenResult.claims));
                        localStorage.setItem('company', idTokenResult.claims.company);
                        localStorage.setItem('auth_token', idTokenResult.token);
                        window.token = idTokenResult.token;
                        $('#toastSigninSuccess').toast('show');
                        $('#toastSigninSuccess').on('shown.bs.toast', function() {
                            setTimeout(function() {
                                $('#toastSigninSuccess').toast('hide');
                                const role = JSON.parse(window.localStorage.getItem('auth_claims')).role.toLowerCase();
                                if (role === 'noc' || role === 'noc-ad') {
                                    window.location.href = "operation.html";
                                } else {
                                    window.location.href = "ops.html";
                                }
                            }, 1000);
                        });
                    }).catch(function(err) {
                        console.log('USERLOGIN getIdToken ERROR', err);
                        return Promise.reject(err);
                    });
            })
            .catch(function(error) {
                showError(error.message);
                console.log('signin', error.message);
            });
    } catch (err) {
        showError(err);
        console.log('Signin', err);
    }
};

const signout = async function() {
    console.log('signout');
    try {
        await window.firebase
            .auth()
            .signOut()
            .then(() => {
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_claims');
                sessionStorage.removeItem('custom_index');
                console.log('OUT', window.location.origin);
                window.location.href = "index.html";
            })
            .catch(function(error) {
                console.log('error', error);
                console.log(error.message);
            });
    } catch (err) {
        showError(err);
        console.log('signout', err);
    }

};

window.auth = {
    signin: signin,
    signout: signout
}