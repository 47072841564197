import firebase from "firebase/app";
import 'firebase/app';
import 'firebase/auth';
import "firebase/storage";
import "firebase/performance";

try {
    const firebaseConfig = JSON.parse(window.FIREBASECONFIG);
    firebase.initializeApp(firebaseConfig);
    window.firebase = firebase;


} catch (error) {
    window.location.href = 'index.html';
    console.log('ERROR', error);
}
export default firebase;