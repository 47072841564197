//
// user.js
// Use this to write your custom JS
//

import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import './chart';
import './toast';
import _ from 'lodash'
import {
    getCSSVariableValue
} from './helpers';


import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LineController,
    LineElement,
    LinearScale,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from 'chart.js';

Chart.register(
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
    annotationPlugin
);

// Collectives chart
const dayChart = document.querySelector('.day-chart');
const weekChartCalls = document.querySelectorAll('.week-chart-calls');
const weekChartCollective = document.querySelectorAll('.week-chart-collectives');
const monthChart = document.querySelectorAll('.month-chart');
const yearChart = document.querySelector('.year-chart');


window.getSettings = async function() {
    const API_URL = sessionStorage.getItem('API_URL');
    const URL = `${API_URL}administration/settings`;
    console.log("URL", URL);
    return await window.axios.get(URL)
        .then(
            function(response) {
                console.log("getSettings", response);
                if (response.status != 200 || response.error || response.data.error) {
                    const message = response.error ? response.error : response.data.message;
                    console.error('getSettings error', message);
                    window.showError(message);
                    return response;
                }
                // The API call was successful!
                return mappingWebWarnings(response.data.config.web_warnings);
            }).catch(function(err) {
            // There was an error
            console.warn('Something went wrong.', err);
            showError(err);
            return false;
        });
};

window.getMainStatsData = async function(hours) {
    const API_URL = sessionStorage.getItem('API_URL');
    const URL = `${API_URL}statistics/statistics_data?hours=${hours}`;
    return await window.axios.get(URL)
        .then(function(response) {
            // The API call was successful!
            // This is the JSON from our response
            if (response.status != 200 || response.error || response.data.error) {
                const message = response.error ? response.error : response.data.message;
                window.showError(message);
                return false;
            }
            return mainStatsData(response.data);
        }).catch(function(err) {
            // There was an error
            console.warn('Something went wrong.', err);
            return err;
        });
};

/*
Get data from last 24 hours
*/
window.getData = async function(hours) {
    const API_URL = sessionStorage.getItem('API_URL');
    const URL = `${API_URL}statistics/statistics?hours=${hours}`;
    await window.axios.get(URL)
        .then(function(response) {
            // The API call was successful!
            // This is the JSON from our response
            if (response.status != 200 || response.error || response.data.error) {
                const message = response.error ? response.error : response.data.message;
                $('#reportDayPage .no-data').show();
                window.showError(message);
                return false;
            }
            getLastHourRealTime();
            console.log("response.data", response.data);

            return createRealTimeChartHours(dayChart, response.data);
        }).catch(function(err) {
            // There was an error
            console.warn('Something went wrong.', err);
        });
};
/*
Get data from last 7 days
*/
window.get7daysData = async function() {
    const API_URL = sessionStorage.getItem('API_URL');
    const URL = `${API_URL}statistics/statistics_7days`;
    return await window.axios.get(URL)
        .then(function(response) {
            // The API call was successful!
            // This is the JSON from our response
            if (response.status != 200 || response.error) {
                const message = response.error ? response.error : response.data.message;
                $('#reportDayPage .no-data').show();
                window.showError(message);
                return false;
            }
            createCumulativeCallsChart('weekChart', weekChartCalls, response.data);
            createCumulativeCollectivesChart('weekChart', weekChartCollective, response.data);
            return true;
        }).catch(function(err) {
            // There was an error
            console.warn('Something went wrong.', err);
            return err;
        });
};

/*
Get data from last 30 days
*/
window.get30daysData = async function() {
    try {
        const API_URL = sessionStorage.getItem('API_URL');
        const URL = `${API_URL}statistics/statistics_30days`;
        await window.axios.get(URL)
            .then(function(response) {
                // The API call was successful!
                // This is the JSON from our response
                if (response.status != 200 || response.error) {
                    const message = response.error ? response.error : response.data.message;
                    $('#reportDayPage .no-data').show();
                    window.showError(message);
                    return false;
                }
                return createCumulativeChart('monthChart', monthChart, response.data);
            }).catch(function(err) {
                // There was an error
                console.warn('Something went wrong.', err);
            });
    } catch (err) {
        console.log(`ERROR ${err}`);
    }
};

const mappingWebWarnings = function(config) {
    try {
        const configMapped = {
            '3': config['high'],
            '2': config['medium'],
            '1': config['low']
        };
        localStorage.setItem('configMapped', JSON.stringify(configMapped));

        return {
            'data': true
        };
    } catch (error) {
        console.error('mappingWebWarnings error', error);
        return {
            'data': false
        };
    }
};

/*
Get data from last hour
*/
const getLastHour = async function(dayChart) {
    getMainStatsData(1);
    get7daysData();
    const API_URL = sessionStorage.getItem('API_URL');
    const URL = `${API_URL}statistics/statistics?hours=1`;
    return await window.axios.get(URL)
        .then(function(response) {
            // The API call was successful!
            // This is the JSON from our response
            if (response.status != 200 || response.error || response.data.error) {
                const message = response.error ? response.error : response.data.message;
                clearTimeout(window.getLastMinute);
                window.showError(message);
                return false;
            }
            clearTimeout(window.getLastMinute);
            updateData(dayChart, formatNow(true), [response.data.affected, response.data.calls, response.data.collectives]);
            return response.data;
        }).catch(function(err) {
            // There was an error
            console.warn('Something went wrong.', err);
            window.showError(err);
            return err;
        });
};

/*
Get data from last hour for sparks in realtime
*/
const getLastHourRealTime = async function() {
    const API_URL = sessionStorage.getItem('API_URL');
    const URL = `${API_URL}statistics/statistics_minutes?minutes=60`;
    return await window.axios.get(URL)
        .then(function(response) {
            // The API call was successful!
            // This is the JSON from our response
            if (response.status != 200 || response.error || response.data.error) {
                const message = response.error ? response.error : response.data.message;
                clearTimeout(window.getLastMinute);
                window.showError(message);
                return false;
            }
            createAffectedSparkRealTime('line', 'sparklineChartUserAffectedRT', response.data, getCSSVariableValue('--chart-affected-light'), getCSSVariableValue('--chart-affected-spark'));
            createCollectivesSparkRealTime('bar', 'sparklineChartCollectivesRT', response.data, getCSSVariableValue('--chart-affected-light'), getCSSVariableValue('--chart-affected-spark'));
            createCallsSparkRealTime('line', 'sparklineChartCallsRT', response.data, getCSSVariableValue('--chart-affected-light'), getCSSVariableValue('--chart-affected-spark'));
            return response.data;
        }).catch(function(err) {
            // There was an error
            console.warn('Something went wrong.', err);
            window.showError(err);
            return err;
        });
};

/*
Get data from last minute
*/
const getLastMinute = async function(dayChart) {
    getMainStatsData(localStorage.getItem('timeInterval'));
    const API_URL = sessionStorage.getItem('API_URL');
    const URL = `${API_URL}statistics/statistics_last_minute`;
    return await window.axios.get(URL)
        .then(function(response) {
            // The API call was successful!
            // This is the JSON from our response
            if (response.status != 200 || response.error || response.data.error) {
                const message = response.error ? response.error : response.data.message;
                clearTimeout(window.getLastMinute);
                window.showError(message);
                addData(dayChart, formatNow(false), [0, 0, 0]);
                return false;
            }
            clearTimeout(window.getLastMinute);
            addData(dayChart, formatNow(false), [response.data.affected, response.data.calls, response.data.collectives]);
            return response.data;
        }).catch(function(err) {
            // There was an error
            console.warn('Something went wrong.', err);
            window.showError(err);
            return err;
        });
};

const createSeverityBar = function(currentChart, allData) {

    if (allData.labels.length == 0 && allData.data.length == 0) {
        document.querySelector(`h3.${currentChart}.no-data`).classList.add('visible');
        document.querySelector(`div.${currentChart}`).classList.remove('visible');
    }
    let percentData = allData.data.map((value) => {
        return (value * 100 / _.sum(allData.data)).toFixed(1);
    });
    const severity = ['Low', 'Medium', 'High'];
    const colorsBySeverity = [getCSSVariableValue('--chart-sev-low'), getCSSVariableValue('--chart-sev-medium'), getCSSVariableValue('--chart-sev-high')];
    let mappedLabels = allData.labels.map((value) => {
        return severity[value];
    });
    let mappedColors = allData.labels.map((value) => {
        return colorsBySeverity[value];
    });

    new Chart(currentChart, {
        type: 'bar',
        options: {
            showAllTollTips: true,
            indexAxis: 'y',
            scales: {
                y: {
                    display: true
                },
                x: {
                    display: false
                }
            },
            elements: {
                bar: {
                    borderWidth: 2,
                    borderColor: 'red'
                },
                point: {
                    radius: 0,
                    hoverRadius: 0
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(ctx) {
                            return `${ctx.raw}%`;
                        }
                    }
                },
                datalabels: {
                    display: true,
                    align: 'end',
                    anchor: 'end',
                    labels: {
                        value: {
                            color: 'white',
                            formatter: function(value, ctx) {
                                return value;
                            },
                            padding: 4
                        }
                    }
                },
            }
        },
        data: {
            labels: mappedLabels,
            datasets: [{
                data: percentData,
                borderColor: getCSSVariableValue('--chart-sev-medium'),
                backgroundColor: getCSSVariableValue('--chart-sev-medium')
            }]
        }
    });
}

const createCollectivesSpark = function(charType, currentChart, data, color) {
    new Chart(currentChart, {
        type: charType,
        options: {
            scales: {
                y: {
                    display: false
                },
                x: {
                    display: false
                }
            },
            elements: {
                line: {
                    borderWidth: 2,
                    borderColor: color
                },
                point: {
                    radius: 0,
                    hoverRadius: 0
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(ctx) {
                            return ctx.raw;
                        }
                    }
                },
                datalabels: {
                    display: true,
                    align: 'end',
                    anchor: 'end',
                    labels: {
                        value: {
                            color: 'white',
                            formatter: function(value, ctx) {
                                return value;
                            },
                            padding: 4
                        }
                    }
                }
            }
        },
        data: {
            labels: dateUTCtoLocal(data.labels),
            datasets: [{
                data: data.collectives,
                borderColor: color,
                backgroundColor: color
            }]
        }
    });
};

const createAffectedSpark = function(charType, currentChart, data, light, color) {
    new Chart(currentChart, {
        type: charType,
        options: {
            scales: {
                y: {
                    display: false
                },
                x: {
                    display: false
                }
            },
            elements: {
                line: {
                    borderWidth: 2,
                    borderColor: color
                },
                point: {
                    radius: 0,
                    hoverRadius: 0
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(ctx) {
                            return ctx.raw;
                        }
                    }
                },
                datalabels: {
                    display: true,
                    align: 'end',
                    anchor: 'end',
                    labels: {
                        value: {
                            color: 'white',
                            formatter: function(value, ctx) {
                                return value;
                            },
                            padding: 4
                        }
                    }
                },
            }
        },
        data: {
            labels: dateUTCtoLocal(data.labels),
            datasets: [{
                data: data.affected,
                borderColor: color,
                backgroundColor: light,
                fill: true
            }]
        }
    });
};

const createAffectedSparkRealTime = function(charType, currentChart, data, light, color) {
    new Chart(currentChart, {
        type: charType,
        options: {
            scales: {
                y: {
                    display: false
                },
                x: {
                    display: false
                }
            },
            elements: {
                line: {
                    borderWidth: 2,
                    borderColor: color
                },
                point: {
                    radius: 0,
                    hoverRadius: 0
                }
            },
            plugins: {
                title: {
                    display: true,
                    align: 'end',
                    text: 'Users affected'
                },
                tooltip: {
                    callbacks: {
                        label: function(ctx) {
                            return ctx.raw === 0 ? '0' : ctx.raw;
                        }
                    }
                },
                datalabels: {
                    display: true,
                    align: 'end',
                    anchor: 'end',
                    labels: {
                        value: {
                            color: 'white',
                            formatter: function(value, ctx) {
                                return value;
                            },
                            padding: 4
                        }
                    }
                },
            }
        },
        data: {
            labels: dateUTCtoLocal(data.labels),
            datasets: [{
                data: data.affected,
                borderColor: color,
                backgroundColor: light,
                fill: true
            }]
        }
    });
};

const createCollectivesSparkRealTime = function(charType, currentChart, data, light, color) {
    new Chart(currentChart, {
        type: charType,
        options: {
            scales: {
                y: {
                    display: false
                },
                x: {
                    display: false
                }
            },
            elements: {
                line: {
                    borderWidth: 2,
                    borderColor: color
                },
                point: {
                    radius: 0,
                    hoverRadius: 0
                }
            },
            plugins: {
                title: {
                    display: true,
                    align: 'end',
                    text: 'Collectives open'
                },
                tooltip: {
                    callbacks: {
                        label: function(ctx) {
                            return ctx.raw === 0.1 ? '0' : ctx.raw - 0.1;
                        }
                    }
                },
                datalabels: {
                    display: true,
                    align: 'end',
                    anchor: 'end',
                    labels: {
                        value: {
                            color: 'white',
                            formatter: function(value, ctx) {
                                return value;
                            },
                            padding: 4
                        }
                    }
                },
            }
        },
        data: {
            labels: dateUTCtoLocal(data.labels),
            datasets: [{
                data: data.collectives,
                borderColor: color,
                backgroundColor: light,
                fill: true
            }]
        }
    });
};

const createCallsSparkRealTime = function(charType, currentChart, data, light, color) {
    new Chart(currentChart, {
        type: charType,
        options: {
            scales: {
                y: {
                    display: false
                },
                x: {
                    display: false
                }
            },
            elements: {
                line: {
                    borderWidth: 2,
                    borderColor: color
                },
                point: {
                    radius: 0,
                    hoverRadius: 0
                }
            },
            plugins: {
                title: {
                    display: true,
                    align: 'end',
                    text: 'Calls'
                },
                tooltip: {
                    callbacks: {
                        label: function(ctx) {
                            return ctx.raw === 0 ? '0' : ctx.raw;
                        }
                    }
                },
                datalabels: {
                    display: true,
                    align: 'end',
                    anchor: 'end',
                    labels: {
                        value: {
                            color: 'white',
                            formatter: function(value, ctx) {
                                return value;
                            },
                            padding: 4
                        }
                    }
                },
            }
        },
        data: {
            labels: dateUTCtoLocal(data.labels),
            datasets: [{
                data: data.calls,
                borderColor: color,
                backgroundColor: light,
                fill: true
            }]
        }
    });
};

const createRealTimeChartHours = function(currentChart, allData) {
    new Chart(currentChart, {
        plugins: [ChartDataLabels,
            {
                beforeDraw: (chart) => {
                    const ctx = chart.canvas.getContext('2d');
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = 'rgba(0,0,0,0)';
                    ctx.fillRect(0, 0, chart.width, chart.height);
                    ctx.restore();
                }
            }
        ],
        options: {
            animation: {
                onComplete: function() {
                    const dayChart = this;
                    const now = new Date();
                    window.getLastMinute = setTimeout(function() {
                        //return getLastHour(dayChart);
                        return true;
                    }, 1 * 60 * 1000);

                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: "bottom",
                    align: "end",
                    usePointStyle: true,
                    padding: 30,
                    boxWidth: 30,
                    fontSize: 30
                },
                datalabels: {
                    display: true,
                    align: 'end',
                    anchor: 'end',
                    labels: {
                        value: {
                            color: getCSSVariableValue('--chart-label-dark'),
                            formatter: function(value, ctx) {
                                /*
                                f (ctx.dataset.label.indexOf('Collectives') > -1) {
                                    return ctx.active ? value : Math.round(value * 1000) / 1000;
                                } else {
                                    return '';
                                }*/
                                return value != 0 && ctx.datasetIndex === 0 ?
                                    value :
                                    '';
                            },
                            padding: 4
                        }
                    }
                },
            },
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: true,
            },
            scales: {
                yAxisOne: {
                    title: {
                        display: true,
                        text: 'Collectives',
                        align: 'center'
                    },
                    type: 'linear',
                    position: 'left',
                    ticks: {
                        callback: function(value) {
                            return value;

                        }
                    },
                    suggestedMin: 0,
                    suggestedMax: 10,
                    grid: {
                        display: false,
                        drawBorder: false,
                        drawOnChartArea: false
                    }
                },
                yAxisTwo: {
                    title: {
                        display: true,
                        text: 'Affected & Calls',
                        align: 'center'
                    },
                    type: 'linear',
                    position: 'right',
                    ticks: {
                        callback: function(value) {
                            return value;
                        }
                    },
                    suggestedMin: 10,
                    suggestedMax: 30,
                    grid: {
                        display: false,
                        drawBorder: false,
                        drawOnChartArea: false
                    }
                }
            },
        },
        data: {
            labels: dateUTCtoLocal(allData.labels),
            datasets: [{
                    type: 'line',
                    label: 'Affected',
                    yAxisID: 'yAxisTwo',
                    borderColor: getCSSVariableValue('--chart-affected'),
                    backgroundColor: getCSSVariableValue('--chart-dot'),
                    data: allData.affected
                },
                {
                    type: 'line',
                    label: 'Calls',
                    yAxisID: 'yAxisTwo',
                    borderColor: getCSSVariableValue('--chart-callings'),
                    backgroundColor: getCSSVariableValue('--chart-dot'),
                    data: allData.calls,
                    hidden: false,
                },
                {
                    type: 'bar',
                    label: 'Collectives',
                    yAxisID: 'yAxisOne',
                    borderColor: getCSSVariableValue('--chart-collectives'),
                    backgroundColor: getCSSVariableValue('--chart-collectives'),
                    data: allData.collectives,

                }
            ],
        },
    });
    createAffectedSpark('line', 'sparklineChartUserAffected', allData, getCSSVariableValue('--chart-affected-light'), getCSSVariableValue('--chart-affected-spark'));
    createCollectivesSpark('bar', 'sparklineChartCollectives', allData, getCSSVariableValue('--chart-collectives'));
}

const createCumulativeCallsChart = function(typeCurrentChart, currentChart, allData) {

    new Chart(currentChart, {
        plugins: [ChartDataLabels],
        options: {

            plugins: {
                legend: {
                    display: true,
                    position: "bottom",
                    align: "end",
                    usePointStyle: false,
                    padding: 30
                },
                datalabels: {
                    display: true,
                    align: 'end',
                    anchor: 'end',
                    labels: {
                        value: {
                            color: getCSSVariableValue('--chart-label-dark'),
                            formatter: function(value, ctx) {
                                if (ctx.dataset.label.indexOf('Collectives') > -1) {
                                    return ctx.active ? value : Math.round(value * 1000) / 1000;
                                } else {
                                    return '';
                                }
                            },
                            padding: 4
                        }
                    }
                },
            },
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                yAxisTwo: {
                    title: {
                        display: true,
                        text: 'Affected & Calls',
                        align: 'center'
                    },
                    type: 'linear',
                    position: 'right',
                    grid: {
                        display: false,
                        drawBorder: false,
                        drawOnChartArea: false
                    }
                },
                range: {
                    position: "bottom",
                    ticks: {
                        padding: 40
                    }
                }
            },
        },
        data: {
            labels: allData.collectives_LW.labels,
            datasets: [{
                    type: 'line',
                    label: 'Affected LW',
                    yAxisID: 'yAxisTwo',
                    borderColor: getCSSVariableValue('--chart-affected'),
                    backgroundColor: getCSSVariableValue('--chart-affected'),
                    pointBackgroundColor: getCSSVariableValue('--chart-affected'),
                    pointStyle: 'rectRot',
                    pointRadius: 3,
                    borderWidth: 2,
                    data: allData.collectives_LW.affected,
                    hidden: false,
                },
                {
                    type: 'line',
                    label: 'Affected TW',
                    yAxisID: 'yAxisTwo',
                    borderColor: getCSSVariableValue('--chart-affected'),
                    backgroundColor: getCSSVariableValue('--chart-dot'),
                    data: allData.collectives_TW.affected,
                    hidden: false,
                },
                {
                    type: 'line',
                    label: 'Calls LW',
                    yAxisID: 'yAxisTwo',
                    borderColor: getCSSVariableValue('--chart-callings'),
                    backgroundColor: getCSSVariableValue('--chart-callings'),
                    pointBackgroundColor: getCSSVariableValue('--chart-callings'),
                    pointStyle: 'rectRot',
                    pointRadius: 3,
                    borderWidth: 2,
                    data: allData.collectives_LW.calls,
                    hidden: false,
                },
                {
                    type: 'line',
                    label: 'Calls TW',
                    yAxisID: 'yAxisTwo',
                    borderColor: getCSSVariableValue('--chart-callings'),
                    backgroundColor: getCSSVariableValue('--chart-dot'),
                    data: allData.collectives_TW.calls,
                    hidden: false,
                }

            ],
        },
    });
    if (typeCurrentChart == 'weekChart') {
        return get30daysData();
    }
}

const createCumulativeCollectivesChart = function(typeCurrentChart, currentChart, allData) {

    new Chart(currentChart, {
        plugins: [ChartDataLabels],
        options: {

            plugins: {
                legend: {
                    display: true,
                    position: "bottom",
                    align: "end",
                    usePointStyle: false,
                    padding: 30
                },
                datalabels: {
                    display: true,
                    align: 'end',
                    anchor: 'end',
                    labels: {
                        value: {
                            color: getCSSVariableValue('--chart-label-dark'),
                            formatter: function(value, ctx) {
                                if (ctx.dataset.label.indexOf('Collectives') > -1) {
                                    return ctx.active ? value : Math.round(value * 1000) / 1000;
                                } else {
                                    return '';
                                }
                            },
                            padding: 4
                        }
                    }
                },
            },
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                yAxisTwo: {
                    title: {
                        display: true,
                        text: 'Collectives',
                        align: 'center'
                    },
                    type: 'linear',
                    position: 'right',
                    grid: {
                        display: false,
                        drawBorder: false,
                        drawOnChartArea: false
                    }
                },
                range: {
                    position: "bottom",
                    ticks: {
                        padding: 40
                    }
                }
            },
        },
        data: {
            labels: allData.collectives_LW.labels,
            datasets: [{
                    type: 'bar',
                    label: 'Collectives LW',
                    yAxisID: 'yAxisTwo',
                    borderColor: getCSSVariableValue('--chart-collectives-compare'),
                    backgroundColor: getCSSVariableValue('--chart-collectives-compare'),
                    data: allData.collectives_LW.collectives,

                },
                {
                    type: 'bar',
                    label: 'Collectives TW',
                    yAxisID: 'yAxisTwo',
                    borderColor: getCSSVariableValue('--chart-collectives'),
                    backgroundColor: getCSSVariableValue('--chart-collectives'),
                    data: allData.collectives_TW.collectives,

                }

            ],
        },
    });
    if (typeCurrentChart == 'weekChart') {
        return get30daysData();
    }
}

const addData = function(chart, label, new_data) {
    chart.data.labels.push(label);
    chart.data.datasets.forEach((dataset, index) => {
        dataset.data.push(new_data[index]);
    });
    removeData(chart);
    chart.update();
};

const updateData = function(chart, label, new_data) {
    const currentLabel = Number(label.split(':')[0]);
    const lastLabel = Number((chart.data.labels[chart.data.labels.length - 1]).split(':')[0]);
    if (currentLabel > lastLabel) {
        chart.data.labels.push(formatNow(true));
        chart.data.datasets.forEach((dataset, index) => {
            dataset.data.push(new_data[index][new_data[index].length - 1]);
        });
        removeData(chart);
    } else {
        chart.data.datasets.forEach((dataset, index) => {
            dataset.data[[dataset.data.length - 1]] = new_data[index][new_data[index].length - 1];
        });
    }
    return chart.update();
};

const removeData = function(chart) {
    chart.data.datasets.forEach((dataset) => {
        dataset.data.shift();
    });
    return chart.data.labels.shift();
};

function updateTimes(currentTime) {
    document.querySelectorAll('span.currentTime').forEach((item) => {
        item.innerHTML = Number(currentTime.split(':')[0]) > 11 ? `${currentTime}pm` : `${currentTime}am`;
    });
    return true;
}

const mainStatsData = function(data) {
    const nowTime = formatNow(false);
    updateTimes(nowTime);
    $('.info-stats .nodes span.heading').text(data.nodes);
    //$('.info-stats .nodes_analyzed span.heading').text(data.nodes_analyzed);
    $('.info-stats .nodes_affected span.heading').text(data.nodes_affected);
    $('.info-stats .collectives span.heading').text(data.collectives);
    $('.info-stats .affected span.heading').text(data.affected);
    $('.info-stats .calls span.heading').text(data.calls);
    $('.info-stats .callers span.heading').text(data.callers);
    //$('.info-stats .absorption span.heading').text(`${data.callers}%`);
    $('.info-stats .absorption span.heading').text('0%');
    if (data.severity_data) {
        createSeverityBar('severityChart', data.severity_data);
    }
    return true;
};

const formatNow = function(isRounded) {
    let now = new Date();
    let hh = String(now.getHours()).padStart(2, '0');
    let mm = String(now.getMinutes()).padStart(2, '0');
    let dd = String(now.getDate()).padStart(2, '0');
    let MM = String(now.getMonth() + 1).padStart(2, '0'); //January is 0!

    now = isRounded ? hh + ':00' : hh + ':' + mm;
    return now;
};

const dateUTCtoLocal = function(times) {
    try {
        console.log('times', times);
        const localTimes = times.map((time) => {
            var today = new Date(time);
            let hh = String(today.getHours()).padStart(2, '0');
            let mm = String(today.getMinutes()).padStart(2, '0');

            const now = hh + ':' + mm;
            return now;
        });
        return localTimes;

    } catch (error) {
        console.error('error', error);
        return times;
    }

};